<script lang="ts" setup>
defineProps({
  sidebarOpen: {
    type: Boolean,
    required: true,
  },
});

const { t } = useI18n();
const localePath = useLocalePath();
const user = useSanctumUser<User>();
const { logout } = useSanctumAuth();
const sanctumClient = useSanctumClient();
const { submit: billingPortal } = useSubmit(
  () =>
    sanctumClient("/billing/portal", {
      method: "GET",
    }),
  {
    onSuccess: (response) => {
      return navigateTo(response.billing_portal_url, {
        external: true,
      });
    },
  },
);

const items = computed(() => {
  const isAdmin = user.value?.permissions.includes(ADMIN_DASHBOARD_VIEW);

  const links = [
    [
      {
        slot: "account",
        label: "",
        disabled: true,
      },
    ],
    [
      {
        label: t("user_dropdown.profile"),
        icon: "i-ph-gear-bold",
        to: localePath("/settings/profile"),
      },
    ],
    [
      {
        label: t("user_dropdown.settings"),
        icon: "i-ph-star-bold",
        click: billingPortal,
      },
    ],
    [
      {
        label: t("user_dropdown.logout"),
        icon: "i-ph-sign-out-bold",
        click: logout,
      },
    ],
  ];

  if (isAdmin) {
    links.splice(2, 0, [
      {
        label: "Admin",
        icon: "i-ph-shield-check-bold",
        to: "/admin/dashboard",
      },
    ]);
  }

  return links;
});
</script>

<template>
  <UDropdown
    :items="items"
    :ui="{ width: 'w-60', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ placement: 'auto' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="white"
        class="w-full"
        :label="sidebarOpen ? user?.name : undefined"
        size="md"
        :class="[open && 'bg-gray-50 dark:bg-gray-800 shadow']"
      >
        <template #leading>
          <UAvatar :src="user?.profile_photo_url" size="2xs" />
        </template>

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>
          {{ $t("user_dropdown.signed_as") }}
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ user?.email }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>

<style></style>
