<script lang="ts" setup>
const user = useSanctumUser<User>();
const { t } = useI18n();
const localePath = useLocalePath();

const links = computed(() => {
  const prices = {
    label: t("app_layout.pricing"),
    to: localePath("/checkout/pricing"),
    icon: "i-ph-star-bold",
  };

  const links = [
    {
      label: t("app_layout.summarizer"),
      icon: "i-ph-book-open-text",
      to: localePath("/tools/summaries"),
    },
    {
      label: t("app_layout.paraphraser"),
      to: localePath("/tools/paraphrases"),
      icon: "i-ph-note",
    },
    {
      label: t("app_layout.essay"),
      to: localePath("/tools/essays"),
      icon: "i-ph-note-pencil",
    },
    {
      label: t("app_layout.quizzes"),
      icon: "i-ph-check-square",
      defaultOpen: false,
      collapsible: sidebarOpen.value,
      to: localePath("/tools/quizzes"),
    },
    {
      label: t("app_layout.tasks"),
      to: localePath("/tools/tasks"),
      icon: "i-ph-list-checks",
    },
    {
      label: t("app_layout.documents"),
      to: localePath("/tools/documents"),
      icon: "i-ph-stack",
    },
  ];

  if (!user.value?.is_subscribed) {
    links.push(prices);
  }

  return links;
});

const sidebarOpen = ref(true);
const panel = ref();

const sidebarCookie = useCookie("sidebarOpen");
const toggleSidebar = () => {
  // Set cookie
  sidebarOpen.value = !sidebarOpen.value;
  if (sidebarOpen.value) {
    panel.value.width = 250;
  } else {
    panel.value.width = 70;
  }
  sidebarCookie.value = sidebarOpen.value ? "show" : "false";
};

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});
const route = useRoute();
const config = useRuntimeConfig();
const title = computed(() =>
  t((route.meta.title as string) ?? "landing.index.title", {
    appName: config.public.appName,
  }),
);

const metaTags = computed(() => {
  const description = t(
    (route.meta.description as string) ?? "landing.index.description",
  );
  const socialTitle = t((route.meta.title as string) ?? "landing.index.title", {
    appName: config.public.appName,
  });
  return [
    ...(i18nHead.value.meta || []),
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: socialTitle,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:site_name",
      content: config.public.appName as string,
    },
    {
      property: "og:image",
      content: config.public.frontendUrl + "/images/meta_image.webp",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: socialTitle,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      name: "twitter:image",
      content: config.public.frontendUrl + "/images/meta_image.webp",
    },
  ];
});

const metaLinks = computed(() => [
  ...(i18nHead.value.link || []),
  { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
]);
</script>
<template>
  <Html :lang="i18nHead.htmlAttrs.lang" :dir="i18nHead.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>

      <template v-for="link in metaLinks" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in metaTags" :key="meta.id">
        <Meta
          :id="meta?.id ?? undefined"
          :property="meta.property"
          :content="meta.content"
          :name="meta.name"
        />
      </template>
    </Head>
    <Body>
      <UDashboardLayout>
        <UDashboardPanel
          ref="panel"
          collapsible
          :width="250"
          :ui="{
            wrapper: 'transition-all duration-300',
          }"
        >
          <UDashboardNavbar
            :ui="{
              container: sidebarOpen
                ? 'flex items-center justify-between flex-1 gap-x-1.5 min-w-0'
                : 'flex items-center justify-center flex-1 gap-x-0 min-w-0',
            }"
          >
            <template v-if="sidebarOpen" #left>
              <ULink :to="localePath('/')">
                <TheLogo class="h-8" />
              </ULink>
            </template>
            <template #right>
              <div class="hidden lg:flex items-center justify-center">
                <UButton
                  :icon="sidebarOpen ? 'i-ph-caret-left' : 'i-ph-caret-right'"
                  size="xs"
                  @click="toggleSidebar"
                />
              </div>
            </template>
          </UDashboardNavbar>

          <UDashboardSidebar>
            <UDashboardSidebarLinks
              :ui="{
                icon: {
                  base: 'flex-shrink-0 w-5 h-5 relative',
                  active: 'text-primary dark:text-white',
                  inactive:
                    'text-gray-400 dark:text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-200',
                },
              }"
              :links="links"
            />
            <div class="flex-1"></div>
            <template #footer>
              <div class="flex flex-col w-full space-y-4 mb-4">
                <div>
                  <UserDropdown :sidebar-open="sidebarOpen" />
                </div>

                <UButton
                  v-if="!user?.is_subscribed"
                  :label="
                    sidebarOpen ? t('app_layout.switch_to_pro') : undefined
                  "
                  :to="localePath('/checkout/pricing')"
                  icon="i-ph-crown-fill"
                  block
                  size="md"
                />
              </div>
            </template>
          </UDashboardSidebar>
        </UDashboardPanel>

        <slot />

        <ProModal />
      </UDashboardLayout>
    </Body>
  </Html>
</template>
<style></style>
